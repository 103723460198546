import React, { useEffect } from "react";
import Script from "next/script";

interface MetaPixelProps {
    pixelId: string;
}

const MetaPixel: React.FC<MetaPixelProps> = ({ pixelId }) => {
    useEffect(() => {
        // Check if the Meta Pixel has already been initialized
        if (typeof window !== "undefined" && !(window as any)._fbqInitialized) {
            // Set the initialization flag
            (window as any)._fbqInitialized = true;

            // Inject the noscript tag once
            if (
                !document.querySelector(
                    `noscript[data-meta-pixel="${pixelId}"]`
                )
            ) {
                const noscript = document.createElement("noscript");
                noscript.setAttribute("data-meta-pixel", pixelId);

                const img = document.createElement("img");
                img.height = 1;
                img.width = 1;
                img.style.display = "none";
                img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
                img.alt = "";

                noscript.appendChild(img);
                document.body.insertBefore(noscript, document.body.firstChild);
            }
        }
    }, [pixelId]);

    return (
        <>
            {/* Render the Script only if not already initialized */}
            {typeof window !== "undefined" &&
                !(window as any)._fbqInitialized && (
                    <Script
                        id={`meta-pixel-script-${pixelId}`} // Unique ID
                        strategy="afterInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${pixelId}');
              fbq('track', 'PageView');
            `,
                        }}
                    />
                )}
        </>
    );
};

export default MetaPixel;
