import { addYears, addDays, toDate, format } from "date-fns";
import { ApiStateType } from "../../../customHooks/useApi";
import { orderList } from "../../../functions/util";
import get from "lodash/get";
import isNumber from "lodash/isNumber";

const entries = (obj: any) => {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i);

    while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }
    return resArray;
};

const getLabelFromData = (
    data: Compleo.IObject,
    fieldName: string,
    language: string
) => {
    const field = get(data, fieldName);
    if (field) {
        const label: string =
            (fieldName === "description" || fieldName === "title") &&
            language !== "pt-BR" &&
            data.translatedData &&
            data.translatedData[language][fieldName]
                ? data.translatedData[language][fieldName]
                : fieldName === "location.provinceOrState"
                ? get(field, `value`) && !isNumber(get(field, `value`))
                    ? get(field, `value`)
                    : get(field, `label-${language}`) ?? get(field, "label")
                : get(field, `label-${language}`) ??
                  get(field, "label") ??
                  field;

        if (label) {
            return label;
        }
    }

    return "";
};

export const getLabelFromFieldNames = (
    fieldNames: Compleo.IObject[],
    data: Compleo.IObject,
    language: string
) => {
    const label = fieldNames
        ?.sort((a: any, b: any) => a.order - b.order)
        .reduce((acc, curr) => {
            if (curr.fieldName.includes("|")) {
                const fieldNameWithMultipleItems = curr.fieldName.split("|");
                const innerSeparator = curr.separator || " - ";

                const internalLabel = fieldNameWithMultipleItems.reduce(
                    (internalAcc: any, internalCurr: any) => {
                        const label = getLabelFromData(
                            data,
                            internalCurr,
                            language
                        );
                        if (label) {
                            return (
                                `${internalAcc}${label}` +
                                (internalCurr ===
                                fieldNameWithMultipleItems[
                                    fieldNameWithMultipleItems.length - 1
                                ]
                                    ? ""
                                    : `${innerSeparator}`)
                            );
                        } else {
                            return internalAcc + "";
                        }
                    },
                    ""
                );

                const internalLabelAndAcc = `${acc}${internalLabel}`;
                if (internalLabelAndAcc) {
                    const separator = acc && internalLabel ? " - " : "";
                    return `${acc}${separator}${internalLabel}`;
                }
            } else {
                const label = getLabelFromData(data, curr.fieldName, language);

                if (label) {
                    const separator = acc ? " - " : "";
                    return `${acc}${separator}${label}`;
                }
            }
            return acc + "";
        }, "");

    return label;
};

export const isFileFromDB = (file: any) => {
    const keys = Object.keys(file);
    if (keys.includes("key") && keys.includes("originalFileName")) {
        return true;
    }
    return false;
};

export const getSortedMetadataComponents = (metadata: Compleo.IObject) => {
    const metadataComponents = orderList(
        metadata?.map((item: any) => {
            if (item.elementConfig === undefined) {
                item.elementConfig = {};
            }

            switch (item.componentType) {
                case "header":
                    const menuElement = item.items?.filter(
                        (i: any) => i.elementType === "menu"
                    );
                    if (
                        menuElement &&
                        Array.isArray(menuElement) &&
                        menuElement.length === 1
                    ) {
                        const menuIndex = item.items?.indexOf(menuElement[0]);
                        item.items[menuIndex].items = orderList([
                            ...item.items[menuIndex].items,
                        ]);
                    }
                    break;
            }

            return item;
        })
    );

    return {
        metadataComponents,
    };
};

export const getMetadata = (
    metadadosRetorno: ApiStateType,
    language: string,
    timeZone?: null | string
) => {
    let metadataComponents = {};
    if (
        metadadosRetorno.response !== null &&
        metadadosRetorno.response.data !== undefined
    ) {
        let initialMetadata = metadadosRetorno.response.data.metadado;

        metadataComponents = orderList(
            initialMetadata.map((item: any) => {
                if (item.elementConfig === undefined) {
                    item.elementConfig = {};
                }

                switch (item.componentType) {
                    case "header":
                        const menuElement = item.items?.filter(
                            (i: any) => i.elementType === "menu"
                        );
                        if (
                            menuElement &&
                            Array.isArray(menuElement) &&
                            menuElement.length === 1
                        ) {
                            const menuIndex = item.items?.indexOf(
                                menuElement[0]
                            );
                            item.items[menuIndex].items = orderList([
                                ...item.items[menuIndex].items,
                            ]);
                        }
                        break;
                }

                return item;
            })
        );
    }

    return {
        metadataComponents,
    };
};

const clearComplexFieldMessageId = (value: string, fieldName: string) => {
    if (value.includes("COMPLEXFIELD:")) {
        return value
            .replace("COMPLEXFIELD:", "")
            .replace(`_${fieldName}`, `.${fieldName}`);
    } else {
        return value;
    }
};
