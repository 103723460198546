import React from "react";
import dynamic from "next/dynamic";

const Header = dynamic(
    () => {
        const promise = import("./header/Header").then((mod) => mod.Header);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const MainArea = dynamic(
    () => {
        const promise = import("./mainArea/MainArea").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const ImageList = dynamic(
    () => {
        const promise = import("./imageList/ImageList").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const VideoList = dynamic(
    () => {
        const promise = import("./videoList").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const Faq = dynamic(
    () => {
        const promise = import("./faq/Faq").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const TestimonialList = dynamic(
    () => {
        const promise = import("./testimonial").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

import Footer from "../components/footer/Footer";

const JobList = dynamic(
    () => {
        const promise = import("./jobList").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const JobDetail = dynamic(
    () => {
        const promise = import("./jobDetail").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const ApplicationForm = dynamic(
    () => {
        const promise = import("./applicationForm").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const Login = dynamic(
    () => {
        const promise = import("./account/Signin").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const Signup = dynamic(
    () => {
        const promise = import("./account/Signup").then((mod) => mod.default);
        return promise;
    },
    { loading: () => null, ssr: false }
);

const ConfirmSignUp = dynamic(
    () => {
        const promise = import("./account/ConfirmSignup").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const ForgotPassword = dynamic(
    () => {
        const promise = import("./account/ForgotPassword").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const CustomHTMLContent = dynamic(
    () => {
        const promise = import("./customHTMLContent").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

interface ICareerSection {
    componentMetadata: Compleo.IObject;
    classes: any;
    uploadFilesFunction: (
        files: any[],
        isPublicBucket?: boolean
    ) => Promise<Compleo.IObject[]>;
    keyData?: Compleo.IObject;
    redirectTo?: string;
}

const CareerSection = ({
    componentMetadata,
    classes,
    uploadFilesFunction,
    keyData,
    redirectTo,
}: ICareerSection) => {
    switch (componentMetadata.componentType) {
        case "header":
            return (
                <Header
                    key={componentMetadata.componentName}
                    elevationScrollThreshold={
                        componentMetadata.elevationScrollThreshold
                            ? componentMetadata.elevationScrollThreshold
                            : undefined
                    }
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    loginEnabled={true}
                    careerPageClasses={classes}
                ></Header>
            );
        case "highlightedArea":
            return (
                <MainArea
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></MainArea>
            );
        case "imageList":
            return (
                <ImageList
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></ImageList>
            );
        case "videoList":
            return (
                <VideoList
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></VideoList>
            );
        case "testimonialList":
            return (
                <TestimonialList
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></TestimonialList>
            );
        case "faq":
            return (
                <Faq
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></Faq>
            );
        case "footer":
            return (
                <Footer
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></Footer>
            );
        case "jobList":
        case "list":
            const keyDataJobList = { ...keyData };

            return (
                <JobList
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyDataJobList}
                ></JobList>
            );
        case "jobDetail":
            const keyDataJobDetail = { ...keyData };

            return (
                <JobDetail
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyDataJobDetail}
                ></JobDetail>
            );
        case "applicationForm":
            return (
                <ApplicationForm
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyData}
                ></ApplicationForm>
            );
        case "login":
            return (
                <Login
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyData}
                    redirectTo={redirectTo}
                ></Login>
            );
        case "signup":
            return (
                <Signup
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyData}
                    redirectTo={redirectTo}
                ></Signup>
            );
        case "confirmsignup":
            return (
                <ConfirmSignUp
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyData}
                    redirectTo={redirectTo}
                ></ConfirmSignUp>
            );
        case "forgotpassword":
            return (
                <ForgotPassword
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    componentMetadata={componentMetadata}
                    careerPageClasses={classes}
                    keyData={keyData}
                    redirectTo={redirectTo}
                ></ForgotPassword>
            );
        case "customHTMLContent":
            return (
                <CustomHTMLContent
                    key={componentMetadata.componentName}
                    name={componentMetadata.componentName}
                    metadata={componentMetadata}
                    careerPageClasses={classes}
                ></CustomHTMLContent>
            );
    }
    return <div></div>;
};

export default CareerSection;
