import { apiDirectCall } from "customHooks/useApi/api";

export const saveDraftTheme = async (
    companyId: number,
    uniqueLink: string,
    draftSiteTheme: any
) => {
    await apiDirectCall("/careers/savedrafttheme", "post", {
        companyId,
        uniqueLink,
        theme: draftSiteTheme,
    });
};

export const publishDraftToProd = async (
    companyId: number,
    uniqueLink: string
) => {
    await apiDirectCall("/careers/publishdrafttoprod", "post", {
        companyId,
        uniqueLink,
    });
};

export const discardDraft = async (companyId: number, uniqueLink: string) => {
    await apiDirectCall("/careers/deletedraftthemeandmetadatas", "post", {
        companyId,
        uniqueLink,
    });
};

export const saveDraftPageMetadata = async (
    companyId: number,
    uniqueLink: string,
    fullMetadataAndTranslation: any,
    moduleName: string,
    metadata: any,
    translation: any,
    replicateComponentChangesToAllPages?: string[]
) => {
    const body = {
        Metadata: metadata,
        Translation:
            Object.keys(translation).length > 0
                ? translation
                : fullMetadataAndTranslation.Translation,
        uniqueLink,
        moduleName,
        companyId,
        replicateComponentChangesToAllPages,
    };

    await apiDirectCall("/careers/savedraftpagemetadata", "post", body);
};

export const deleteMetadataComponent = async (
    companyId: number,
    uniqueLink: string,
    fullMetadataAndTranslation: any,
    moduleName: string,
    moduleMetadata: any,
    componentName: string,
    updatedTranslation: any
) => {
    const deletedComponentOrder = moduleMetadata.find((m: any) => {
        return m.componentName === componentName;
    })?.order;

    const newMetadata = [...moduleMetadata]
        .filter((m: any) => m.componentName !== componentName)
        .map((m: any) => {
            if (m.order > deletedComponentOrder) {
                m.order--;
            }
            return m;
        });

    await saveDraftPageMetadata(
        companyId,
        uniqueLink,
        fullMetadataAndTranslation,
        moduleName,
        newMetadata,
        updatedTranslation
    );
};

export const reorderHeaderAndFooter = (metadataToBeReordered: any[]) => {
    const newMetadata = [...metadataToBeReordered];
    const headerIndex = newMetadata.findIndex(
        (m: any) => m.componentType === "header"
    );
    const footerIndex = newMetadata.findIndex(
        (m: any) => m.componentType === "footer"
    );
    if (headerIndex > -1 && footerIndex > -1) {
        const footer = newMetadata.splice(footerIndex, 1)[0];
        newMetadata.push(footer);

        const header = newMetadata.splice(headerIndex, 1)[0];
        newMetadata.unshift(header);
    }
    return newMetadata.map((item: any, index: number) => ({
        ...item,
        order: index + 1,
    }));
};

export const insertMetadataComponent = async (
    companyId: number,
    uniqueLink: string,
    fullMetadataAndTranslation: any,
    moduleName: string,
    moduleMetadata: any,
    componentName: string,
    newComponentMetadata: any,
    updatedTranslation: any,
    replicateComponentChangesToAllPages?: string[]
) => {
    let newMetadata = [...moduleMetadata];
    const hasSameNameComponent =
        newMetadata.filter((m: any) => m.componentName === componentName)
            .length > 0;
    if (!hasSameNameComponent) {
        newMetadata.push(newComponentMetadata);

        newMetadata = reorderHeaderAndFooter(newMetadata);

        await saveDraftPageMetadata(
            companyId,
            uniqueLink,
            fullMetadataAndTranslation,
            moduleName,
            newMetadata,
            updatedTranslation,
            replicateComponentChangesToAllPages
        );
    }
};

export const updateMetadataComponent = async (
    companyId: number,
    uniqueLink: string,
    fullMetadataAndTranslation: any,
    moduleName: string,
    moduleMetadata: any,
    componentName: string,
    updatedComponent: any,
    updatedTranslation: any,
    replicateComponentChangesToAllPages?: string[]
) => {
    const newMetadata = [...moduleMetadata];
    const oldComponent = newMetadata.filter(
        (m: any) => m.componentName === componentName
    );

    if (
        oldComponent &&
        Array.isArray(oldComponent) &&
        oldComponent.length === 1
    ) {
        const currentComponentIndexOf = newMetadata.indexOf(oldComponent[0]);
        newMetadata[currentComponentIndexOf] = {
            ...oldComponent[0],
            ...updatedComponent,
        };

        await saveDraftPageMetadata(
            companyId,
            uniqueLink,
            fullMetadataAndTranslation,
            moduleName,
            newMetadata,
            updatedTranslation,
            replicateComponentChangesToAllPages
        );
    }
};
