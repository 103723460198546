import { AwsClient } from "aws4fetch";

interface IObject {
    [key: string]: any;
}

const apiDirectCallAWS4Fetch = async (
    endpoint: string,
    verb: string = "get",
    params: IObject = {},
    options: IObject = {},
    fullUrl: boolean = false
) => {
    const initialEndPoint = process.env.NEXT_PUBLIC_REACT_APP_API_ADDRESS;
    const tokenApiResponse = await fetch("/api/user/getToken", {
        method: "POST",
    });
    const tokenData = await tokenApiResponse.json();
    if (tokenApiResponse.status !== 200) {
        console.log("error getting token from tokenApi");
        return tokenApiResponse;
    }
    const accessKeyId = tokenData.Credentials.AccessKeyId;
    const secretAccessKey = tokenData.Credentials.SecretKey;
    const sessionToken = tokenData.Credentials.SessionToken;
    const region = "us-east-1";
    const service = "execute-api";

    const aws = new AwsClient({
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        service,
        retries: 2,
    });

    const optionsRequest = options;

    let endPointLocal = initialEndPoint + endpoint;
    if (fullUrl) {
        endPointLocal = endpoint;
    }
    if (verb === "get") {
        const response = await aws.fetch(endPointLocal, optionsRequest);
        return response;
    } else if (verb === "post") {
        const fetchOptions = {
            method: "POST",
            body: JSON.stringify(params),
            withCredentials: false,
        };

        const response = await aws.fetch(endPointLocal, fetchOptions);
        return response;
    } else {
        const fetchOptions = {
            method: "PUT",
            body: JSON.stringify(params),
            withCredentials: false,
        };
        const response = await aws.fetch(endPointLocal, fetchOptions);

        return response;
    }
};

export default apiDirectCallAWS4Fetch;
