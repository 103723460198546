import React, { useCallback, useMemo } from "react";
import CareerPage from "./components/CareerPage";
import { CompleoCareerPageProvider } from "./useCompleoCareerPageContext";
import { apiDirectCall } from "../useApi/api";
import { NextRouter } from "next/router";
import { isFileFromDB, getSortedMetadataComponents } from "./helpers/helper";
import { clearAccentuatedAndSpecialCharFromString } from "functions/util";

interface IUseCompleoCareerPageProps {
    language: string;
    moduleMetadata: any;
    moduleName: string;
    uniqueLink: string;
    companyId: number;
    router: NextRouter;
    translationNamespace: string[];
    showEditCareersButton: boolean;
    isCustomDomain: boolean;
    initialData?: Compleo.IObject;
    mainFormMetadata?: any;
    additionalMetadata?: any[];
    jobScreeningQuestionnaireData?: Compleo.IObject;
    fullMetadataAndTranslation?: any;
    hasDraft?: boolean;
    languages?: string[];
    enableMultipleApplicationsPerJob?: boolean;
    companyPrivacyPolicyUrl?: string;
}

export const useCompleoCareerPage = (params: IUseCompleoCareerPageProps) => {
    const {
        language,
        moduleMetadata,
        moduleName,
        uniqueLink,
        companyId,
        router,
        translationNamespace,
        initialData,
        mainFormMetadata,
        additionalMetadata,
        jobScreeningQuestionnaireData,
        showEditCareersButton,
        fullMetadataAndTranslation,
        hasDraft,
        languages,
        enableMultipleApplicationsPerJob,
        companyPrivacyPolicyUrl,
        isCustomDomain,
    } = params;

    const metadata = useMemo(
        () => getSortedMetadataComponents(moduleMetadata),
        [moduleMetadata]
    );

    const uploadFiles = useCallback(
        async (files: any[], isPublicBucket = false) => {
            const valueDefinition: Compleo.IObject[] = [];
            const re = /(?:\.([^.]+))?$/;
            const filesAndExtensions: Compleo.IObject = [];
            if (files && files.length) {
                for (const fileDef of files) {
                    const file = fileDef?.FileInfo || fileDef;
                    if (!isFileFromDB(file)) {
                        const extension = (re.exec(file.name) || [])[1];
                        filesAndExtensions.push({
                            extension: extension,
                            originalFileName:
                                clearAccentuatedAndSpecialCharFromString(
                                    file.name
                                ),
                            fieldName: file.fieldName,
                            contentType: file.type,
                        });
                    }
                }
            }

            const requestUrl = isPublicBucket
                ? "/general/requestpublics3url"
                : "/general/requests3url";

            if (filesAndExtensions.length) {
                const axPost = await apiDirectCall(requestUrl, "post", {
                    files: filesAndExtensions,
                    companyId: companyId,
                });
                const urls = axPost.data;
                if (files && files.length && urls) {
                    for (const fileDef of files) {
                        const file = fileDef?.FileInfo || fileDef;
                        if (!isFileFromDB(file)) {
                            const returnData = urls.filter(
                                (u: any) =>
                                    u.originalFileName ===
                                        clearAccentuatedAndSpecialCharFromString(
                                            file.name
                                        ) && u.fieldName === file.fieldName
                            )[0];
                            const signedUrl = returnData.uploadURL;
                            const filename = returnData.fileName;
                            const options = {
                                headers: {
                                    "Content-Type":
                                        returnData.contentType || file.type,
                                    "x-amz-tagging": `originalFileName=${clearAccentuatedAndSpecialCharFromString(
                                        file.name
                                    )}`,
                                },
                            };
                            const axiosReturn = await apiDirectCall(
                                signedUrl,
                                "put",
                                file,
                                options,
                                true,
                                "none"
                            );
                            const itemToAdd: Compleo.IObject = {
                                key: filename,
                                originalFileName: file.name,
                            };
                            if ((fileDef.tags || []).length) {
                                itemToAdd.tags = fileDef.tags;
                            }

                            valueDefinition.push(itemToAdd);
                        } else {
                            valueDefinition.push(file);
                        }
                    }
                }
            }

            return valueDefinition;
        },
        []
    );

    return (
        <CompleoCareerPageProvider
            metadata={metadata.metadataComponents}
            companyId={companyId}
            uniqueLink={uniqueLink}
            moduleName={moduleName}
            language={language}
            translationNamespace={translationNamespace}
            fullMetadataAndTranslation={fullMetadataAndTranslation}
            initialData={initialData}
            mainFormMetadata={mainFormMetadata}
            additionalMetadata={additionalMetadata}
            jobScreeningQuestionnaireData={jobScreeningQuestionnaireData}
            showEditCareersButton={showEditCareersButton}
            hasDraft={hasDraft}
            supportedLanguages={languages || ["pt-BR"]}
            enableMultipleApplicationsPerJob={enableMultipleApplicationsPerJob}
            companyPrivacyPolicyUrl={companyPrivacyPolicyUrl}
            isCustomDomain={isCustomDomain}
        >
            <CareerPage uploadFilesFunction={uploadFiles} router={router} />
        </CompleoCareerPageProvider>
    );
};

export default useCompleoCareerPage;
