import React, { useEffect } from "react";
import Script from "next/script";

interface GoogleTagManagerProps {
    gtmId: string;
}

const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({ gtmId }) => {
    useEffect(() => {
        // Check if GTM is already initialized
        if (typeof window !== "undefined" && !(window as any)._gtmInitialized) {
            // Set the initialization flag
            (window as any)._gtmInitialized = true;

            // Inject the noscript iframe if it doesn't already exist
            if (!document.querySelector(`noscript[data-gtm="${gtmId}"]`)) {
                const noscript = document.createElement("noscript");
                noscript.setAttribute("data-gtm", gtmId);

                const iframe = document.createElement("iframe");
                iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
                iframe.height = "0";
                iframe.width = "0";
                iframe.style.display = "none";
                iframe.style.visibility = "hidden";
                iframe.title = "GTM";

                noscript.appendChild(iframe);
                document.body.insertBefore(noscript, document.body.firstChild);
            }
        }
    }, [gtmId]);

    return (
        <>
            {/* Render the GTM Script only if not already initialized */}
            {typeof window !== "undefined" &&
                !(window as any)._gtmInitialized && (
                    <Script
                        id={`gtm-script-${gtmId}`} // Unique ID to prevent duplication
                        strategy="afterInteractive" // Ensures the script loads after the page is interactive
                        dangerouslySetInnerHTML={{
                            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');
            `,
                        }}
                    />
                )}
        </>
    );
};

export default GoogleTagManager;
