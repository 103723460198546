import React from "react";

interface ICompleoCareerPageContext {
    metadata: Compleo.IObject;
    companyId: number;
    uniqueLink: string;
    moduleName: string;
    language: string;
    translationNamespace: string[];
    fullMetadataAndTranslation?: Compleo.IObject;
    isCustomDomain: boolean;
    initialData?: Compleo.IObject;
    mainFormMetadata?: Compleo.IObject;
    additionalMetadata?: Compleo.IObject[];
    jobScreeningQuestionnaireData?: Compleo.IObject;
    showEditCareersButton: boolean;
    hasDraft?: boolean;
    supportedLanguages: string[];
    enableMultipleApplicationsPerJob?: boolean;
    companyPrivacyPolicyUrl?: string;
}

const CompleoCareerPageContext = React.createContext<
    ICompleoCareerPageContext | undefined
>(undefined);

interface ICompleoCareerPageProvider {
    metadata: Compleo.IObject;
    companyId: number;
    uniqueLink: string;
    moduleName: string;
    language: string;
    translationNamespace: string[];
    children: JSX.Element;
    isCustomDomain: boolean;
    fullMetadataAndTranslation?: Compleo.IObject;
    initialData?: Compleo.IObject;
    mainFormMetadata?: Compleo.IObject;
    additionalMetadata?: Compleo.IObject[];
    jobScreeningQuestionnaireData?: Compleo.IObject;
    showEditCareersButton: boolean;
    hasDraft?: boolean;
    supportedLanguages: string[];
    enableMultipleApplicationsPerJob?: boolean;
    companyPrivacyPolicyUrl?: string;
}

export const CompleoCareerPageProvider = (
    props: ICompleoCareerPageProvider
) => {
    const {
        metadata,
        companyId,
        uniqueLink,
        moduleName,
        language,
        translationNamespace,
        children,
        fullMetadataAndTranslation,
        initialData,
        mainFormMetadata,
        additionalMetadata,
        jobScreeningQuestionnaireData,
        showEditCareersButton,
        hasDraft,
        supportedLanguages,
        enableMultipleApplicationsPerJob,
        companyPrivacyPolicyUrl,
        isCustomDomain,
    } = props;

    const [contextValue, setContextValue] =
        React.useState<ICompleoCareerPageContext>({
            metadata,
            companyId,
            uniqueLink,
            moduleName,
            language,
            translationNamespace,
            fullMetadataAndTranslation,
            initialData,
            mainFormMetadata,
            additionalMetadata,
            jobScreeningQuestionnaireData,
            showEditCareersButton,
            hasDraft,
            supportedLanguages,
            enableMultipleApplicationsPerJob,
            companyPrivacyPolicyUrl,
            isCustomDomain,
        });

    return (
        <CompleoCareerPageContext.Provider value={contextValue}>
            {children}
        </CompleoCareerPageContext.Provider>
    );
};

const useValues = () => {
    const context = React.useContext(CompleoCareerPageContext);
    if (!context) {
        throw new Error(
            `useValues must be used within a CompleoCareerPageProvider`
        );
    }
    const returnData = context;
    return returnData;
};

const useCompleoCareerPageContext: () => [ICompleoCareerPageContext] = () => {
    return [useValues()];
};

export default useCompleoCareerPageContext;
