import { v4 } from "uuid";
import * as formatValues from "./formatValues";
import { currencyLanguageTypes } from "./formatValues";
import { isValid } from "date-fns";
import cache from "memory-cache";

export const getUniqueLinkFromDomain = async (
    apiAddress: string,
    hostname: string,
    minutes: number
) => {
    const url = `${apiAddress}/careerssettings/uniquelinkbydomain/${hostname}`;
    const cached = cache.get(url);
    if (cached) {
        console.log("getUniqueLinkFromDomain cachedFetch", cached);
        return cached;
    }
    const res = await fetch(url);
    const data = await res.json();
    cache.put(url, data, 1000 * 60 * minutes);
    return data;
};

export const uuid = () => {
    return v4();
};

export const hasFoundTheme = (theme: Compleo.IObject) => {
    return theme?.theme?.palette ? true : false;
};

export const getImageSize = (url: string) => {
    return new Promise<any>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.onerror = () => {
            reject({});
        };
        img.src = url;
    });
};

export const getLanguageLabel = (language: string) => {
    let languageLabel: string = language;

    switch (language.toUpperCase()) {
        case "PT-BR":
            languageLabel = "Português (Brasil)";
            break;
        case "EN-US":
            languageLabel = "Inglês (EUA)";
            break;
        case "EN-UK":
        case "EN-GB":
            languageLabel = "Inglês (Reino Unido)";
            break;
        case "PT-PT":
            languageLabel = "Português (Portugal)";
            break;
        case "ES-ES":
            languageLabel = "Espanhol (Espanha)";
            break;
    }

    return languageLabel;
};

export const getDraftData = async (
    apiAddressRoot: string,
    uniqueLink: string,
    modules: Compleo.IObject[],
    language: string
) => {
    let responseJson = await getAllData(
        apiAddressRoot,
        uniqueLink,
        modules,
        language,
        true
    );

    let themeDB = responseJson.theme;

    const mainModule = modules[0];

    const hasDraftMetadataAndTranslation = hasFoundMetadataTranslation(
        responseJson?.metadatas,
        responseJson?.translations,
        mainModule.module,
        mainModule.type
    );
    const hasDraftTheme = hasFoundTheme(themeDB);

    const hasDraft = hasDraftMetadataAndTranslation || hasDraftTheme;

    if (!hasDraftMetadataAndTranslation || !hasDraftTheme) {
        const responseJsonProd = await getAllData(
            apiAddressRoot,
            uniqueLink,
            modules,
            language,
            false
        );

        if (!hasDraftMetadataAndTranslation) {
            responseJson = responseJsonProd;
        }

        if (!hasDraftTheme) {
            themeDB = responseJsonProd.theme;
        }
    }

    return [responseJson, themeDB, hasDraft, hasDraftMetadataAndTranslation];
};

export const getAllData = async (
    apiAddressRoot: string,
    uniqueLink: string,
    modules: Compleo.IObject[],
    language: string,
    isDraft: boolean
) => {
    const apiRequestBody = {
        modules: modules,
        uniqueLink: uniqueLink,
        modulesTranslation: modules,
        language,
        isDraft,
        useCacheData: false,
    };

    const apiEndpoint = `${apiAddressRoot}/careers/getalldata`;

    const apiResponse = await fetch(apiEndpoint, {
        method: "post",
        body: JSON.stringify({ ...apiRequestBody }),
    });

    const responseJson = await apiResponse.json();
    if (apiResponse.status !== 200) {
        console.error("Failed to fetch data - error", responseJson);
        throw new Error("Failed to fetch careers page data");
    }

    return responseJson;
};

export const hasFoundMetadataTranslation = (
    metadatas: Compleo.IObject[],
    translations: Compleo.IObject[],
    moduleName: string,
    type: string
) => {
    const metadata = metadatas.filter(
        (m: any) => m.module === moduleName && m.type === type
    )[0];
    if (metadata && metadata.data?.metadado?.length > 0) {
        const translation = translations.filter(
            (m: any) => m.module === moduleName && m.type === type
        )[0];
        if (
            translation &&
            translation.data &&
            Object.keys(translation.data).length > 0
        ) {
            return true;
        }
    }

    return false;
};

export const orderList = (objectCol: any) => {
    const newCollection = objectCol;
    newCollection?.sort((a: any, b: any) => {
        if (a.order > b.order) {
            return 1;
        }
        if (a.order < b.order) {
            return -1;
        }
        if (a.order === b.order) {
            if (a.lineOrder !== undefined && b.lineOrder !== undefined) {
                return a.lineOrder - b.lineOrder;
            }
        }
        return 0;
    });
    return newCollection;
};

export const clearAccentuatedAndSpecialCharFromString = (value: string) => {
    if (typeof value === "string") {
        return value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[()$@%#\/&*!:]/g, "");
    } else {
        return value;
    }
};

export const clearArrayInfo = (value: string) => {
    const insideBrackets = value.match(/\[(.*?)\]/);
    if (insideBrackets === null) {
        return value;
    } else {
        const valueToReplace = insideBrackets[0];
        return value.replace(valueToReplace, "");
    }
};

export const replaceKeySeparator = (
    json: string,
    oldKeySeparator: string,
    newKeySeparator: string
) => {
    const obj = JSON.parse(json, function (k, v) {
        if (k.includes(oldKeySeparator)) {
            var newKey = k.replace(oldKeySeparator, newKeySeparator);
            this[newKey] = v;
            return;
        }
        return v;
    });
    return obj;
};

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        console.log("toBase64 file", file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === "string") {
                resolve(reader.result.split(",")[1]);
            } else {
                reject({ message: "Error converting to base64 string" });
            }
        };
        reader.onerror = (error) => reject(error);
    });

export const convertParserToCompleoPattern = (
    compleoValues: Compleo.IObject,
    parserResult: Compleo.ParserResult.IApplicant,
    replaceValues: boolean = false,
    metadata: Compleo.IObject[] = []
) => {
    const replaceValue = (
        origin?: any,
        newObj?: any,
        replaceValues: boolean = false
    ) => {
        if (replaceValues) {
            return newObj !== undefined ? newObj : origin;
        } else {
            return origin !== undefined ? origin : newObj;
        }
    };

    const processDate = (parserDate?: {
        day: number;
        month: number;
        year: number;
    }) => {
        if (parserDate !== undefined && typeof parserDate === "object") {
            const newDate = new Date(
                parserDate.year,
                (parserDate.month || 2) - 1,
                parserDate.day || 1
            );
            return newDate.toISOString();
        } else {
            return null;
        }
    };

    const processEducationLevel = (parserLevel?: string) => {
        const returnValue: Compleo.IObject = {};
        switch (parserLevel) {
            case "Basico":
                returnValue.value = "CP_Basic";
                break;
            case "Tecnico Segundo Grau":
                returnValue.value = "CP_Technician";
                break;
            case "Superior":
                returnValue.value = "CP_Superior";
                break;
            case "MBA":
                returnValue.value = "CP_MBA";
                break;
            case "Pos-Graduacao":
                returnValue.value = "CP_Postgraduate";
                break;
            case "Mestrado":
                returnValue.value = "CP_Master";
                break;
            case "Doutorado":
                returnValue.value = "CP_Doctorate";
                break;
            default:
                returnValue.value = "CP_NotInformed";
                break;
        }
        return returnValue;
    };

    const processEducationStatus = (parserStatus?: string) => {
        const returnValue: Compleo.IObject = {};
        switch (parserStatus) {
            case "Concluido":
                returnValue.value = "CP_Completed";
                break;
            case "Trancado":
                returnValue.value = "CP_NotCompleted";
                break;
            case "Andamento":
                returnValue.value = "CP_InProgress";
                break;
            default:
                returnValue.value = "CP_NotInformed";
                break;
        }
        return returnValue;
    };

    const educationFromParser: Compleo.Applicant.IEducation[] | undefined = (
        (parserResult || {}).Formacao || []
    ).map((f: Compleo.ParserResult.IFormacao) => {
        const objReturn: Compleo.Applicant.IEducation = {
            schoolName: f.Escola,
            courseName: f.Curso,
            startDate: processDate(f.Inicio),
            endDate: processDate(f.Termino),
            level: processEducationLevel(f.Tipo),
            status: processEducationStatus(f.Status),
        };
        return objReturn;
    });

    const historyFromParser:
        | Compleo.Applicant.IProfessionalHistory[]
        | undefined = ((parserResult || {}).Historico || []).map(
        (f: Compleo.ParserResult.IHistorico) => {
            const objReturn: Compleo.Applicant.IProfessionalHistory = {
                companyName: f.Empresa,
                position: f.Cargo,
                startDate: processDate(f.Inicio),
                endDate: processDate(f.Termino),
                currentPosition: processDate(f.Termino) === null,
            };
            return objReturn;
        }
    );
    const returnObj: Compleo.Applicant.IApplicant = { ...compleoValues };
    returnObj.name = replaceValue(
        returnObj.name,
        parserResult.Nome,
        replaceValues
    );
    returnObj.email = replaceValue(
        returnObj.email,
        (parserResult.Email || [])[0],
        replaceValues
    );
    returnObj.email2 = replaceValue(
        returnObj.email,
        (parserResult.Email || [])[1],
        replaceValues
    );

    const educationHistoryMetadata = metadata.find(
        (obj) => obj.fieldName === "educationHistory"
    );

    if (
        educationHistoryMetadata ||
        metadata === undefined ||
        metadata.length === 0
    ) {
        returnObj.educationHistory = replaceValue(
            returnObj.educationHistory,
            educationFromParser,
            replaceValues
        );
    }

    const professionalHistoryMetadata = metadata.find(
        (obj) => obj.fieldName === "professionalHistory"
    );

    if (
        professionalHistoryMetadata ||
        metadata === undefined ||
        metadata.length === 0
    ) {
        returnObj.professionalHistory = replaceValue(
            returnObj.professionalHistory,
            historyFromParser,
            replaceValues
        );
    }

    return { ...compleoValues, ...returnObj };
};

export interface ITransformDBData {
    value: any;
    t: any;
    language: string;
    replaceOption?: string;
    customDateFormat?: "dateWithTime" | "dateRelative" | "time";
    customType?: "phone" | "phoneWhatsApp";
    numberDecimalScale?: number;
}
export const transformDBData: (params: ITransformDBData) => any = (
    params: ITransformDBData
) => {
    const {
        value,
        t,
        language,
        replaceOption = "",
        customDateFormat,
        customType,
        numberDecimalScale,
    } = params;
    if (value === undefined || value === "" || value === null) {
        return replaceOption;
    }
    if (customType) {
        switch (customType) {
            case "phone":
            case "phoneWhatsApp":
                return formatValues.maskPhone(value);
            default:
                return value;
        }
    }

    switch (typeof value) {
        case "boolean":
            if (value.toString().toLowerCase() === "true") {
                return t("booleanTrue");
            } else {
                return t("booleanFalse");
            }
        case "object":
            if (!Object.keys(value).length) {
                return replaceOption;
            } else if (Array.isArray(value)) {
                if (value.length === 0) {
                    return replaceOption;
                } else {
                    switch (typeof value[0]) {
                        case "boolean":
                        case "object":
                            return value.map((item: any) =>
                                transformDBData({
                                    value: item,
                                    t,
                                    language,
                                    replaceOption,
                                    customDateFormat: customDateFormat,
                                })
                            );
                        default:
                            return value;
                    }
                }
            } else if (
                value?.value &&
                (value?.label || value[`label-${language}`])
            ) {
                const keyNames = Object.keys(value || {});
                const labelLanguage = `label-${language}`;
                const isList =
                    (keyNames.includes("label") ||
                        keyNames.includes(labelLanguage)) &&
                    keyNames.includes("value");
                if (isList) {
                    return value[labelLanguage] || value["label"];
                }
            } else if (value?.value !== undefined) {
                if (value?.currency !== undefined) {
                    return formatValues.maskCurrency(
                        value.value,
                        language as currencyLanguageTypes,
                        value?.currency,
                        numberDecimalScale
                    );
                } else {
                    return formatValues.maskNumber(
                        value.value,
                        language as currencyLanguageTypes,
                        numberDecimalScale
                    );
                }
            } else if (isValid(value)) {
                return formatValues.formatDate(value, language, "dayMonthYear");
            }
            break;
        case "string":
            if (isValidIso8601(value)) {
                switch (customDateFormat) {
                    case "dateRelative":
                        return formatValues.formatDate(
                            value,
                            language,
                            "relative"
                        );
                    case "dateWithTime":
                        return formatValues.formatDate(
                            value,
                            language,
                            "dayMonthYearTime"
                        );
                    case "time":
                        return formatValues.formatDate(value, language, "time");
                    default:
                        return formatValues.formatDate(
                            value,
                            language,
                            "dayMonthYear"
                        );
                }
            }
            return value;
        case "number":
            return formatValues.maskNumber(
                value,
                language as currencyLanguageTypes,
                numberDecimalScale
            );
    }
    if (typeof value === "object") {
        return replaceOption;
    }
    return value;
};

export const isValidIso8601 = (value: string) => {
    if (isValid(new Date(value))) {
        const dateParsed = new Date(Date.parse(value));
        const iso8601Value = dateParsed.toISOString();
        if (value === iso8601Value) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const clearString = (str?: string, replaceWith: string = "") => {
    if (str) {
        return str.replace(/[^A-Z0-9]+/gi, replaceWith);
    }
    return str;
};

export const getExtension = (fileName: string) => {
    const re = /(?:\.([^.]+))?$/;
    return ((re.exec(fileName) || [])[1] || "").toLowerCase();
};

export const handleFileNameUpload = (value: string) => {
    if (typeof value === "string") {
        return clearFileName(clearAccentuatedString(value).replace(" ", "_"));
    } else {
        return value;
    }
};

export const clearAccentuatedString = (value: string) => {
    if (typeof value === "string") {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    } else {
        return value;
    }
};

export const clearFileName = (value: string) => {
    const strClean1 = clearAccentuatedString(value);
    const fileNameCleared = strClean1.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "_");
    return fileNameCleared;
};

function* chunk(s: string, maxBytes: number) {
    const decoder = new TextDecoder("utf-8");
    let buf = new TextEncoder().encode(s);
    while (buf.length) {
        let i = buf.lastIndexOf(32, maxBytes + 1);
        if (i < 0) i = buf.indexOf(32, maxBytes);
        if (i < 0) i = buf.length;
        yield decoder.decode(buf.slice(0, i));
        buf = buf.slice(i + 1);
    }
}

export const splitStringByByteLength = (d: string, maxBytes: number) => {
    const returnData: string[] = [];
    for (let s of chunk(d, maxBytes)) {
        return returnData.push(s);
    }
    return returnData;
};

/**
 * Logs to console if in development mode
 * @param args parameters passed to `console.log`
 */
export function verbose(...args: any[]) {
    if (process.env.NODE_ENV === "development") {
        console.log(...args);
    }
}

export function loadFontsIfRequired(fonts: string[], loadedFonts: Set<string>) {
    const fontsToLoad = fonts.filter((x) => !loadedFonts.has(x));
    if (!fontsToLoad.length) return loadedFonts;
    loadFonts(fontsToLoad);
    return new Set([...loadedFonts, ...fontsToLoad].sort());
}

export async function loadFonts(fonts: string[]) {
    return new Promise<boolean>((resolve, reject) => {
        try {
            const WebFont = require("webfontloader");
            WebFont.load({
                google: {
                    families: fonts,
                },
                active: () => {
                    verbose(
                        "state/actions -> loadFonts: webfonts loaded",
                        fonts
                    );
                    resolve(true);
                },
                inactive: () => {
                    verbose(
                        "state/actions -> loadFonts: webfonts could not load",
                        fonts
                    );
                    resolve(false);
                },
            });
        } catch (err) {
            resolve(false);
        }
    });
}

export const getFirstLetterNameLastName = (value: string) => {
    if (!value || typeof value !== "string") {
        return null;
    }
    const arrayNames = value.trim().split(" ");
    if (arrayNames.length === 1) {
        return value.charAt(0);
    } else {
        return `${arrayNames[0].charAt(0)}${arrayNames[
            arrayNames.length - 1
        ].charAt(0)}`;
    }
};

export const getTranslatedLanguage = (language: string) => {
    const languagesTranslation: Compleo.IObject = {
        "pt-BR": "Português",
        "en-US": "English",
        "es-ES": "Español",
        "en-UK": "English",
        "fr-FR": "Français",
        "de-DE": "Deutsch",
        "en-GB": "English",
        "zh-CN": "中文",
        "zh-TW": "繁體中文",
        en: "English",
        pt: "Português",
        es: "Español",
        fr: "Français",
        it: "Italiano",
        de: "Deutsch",
    };

    return languagesTranslation[language] || language;
};

export async function readableStreamToFile(
    stream: ReadableStream,
    fileName: string,
    mimeType: string
): Promise<File> {
    const reader = stream.getReader();
    const chunks: Uint8Array[] = [];
    let done: boolean = false;
    let value: Uint8Array | null = null;

    while (!done) {
        ({ done, value } = await reader.read());
        if (value) {
            chunks.push(value);
        }
    }

    const blob = new Blob(chunks, { type: mimeType });

    const file = new File([blob], fileName, { type: mimeType });

    return file;
}

export const getImageType = (url: string): string => {
    const extension = url.split(".").pop()?.toLowerCase();
    switch (extension) {
        case "jpg":
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        case "webp":
            return "image/webp";
        case "gif":
            return "image/gif";
        case "svg":
            return "image/svg+xml";
        default:
            return "application/octet-stream"; // Default fallback type
    }
};
