import React, { useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";

import { NextRouter } from "next/router";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";
import { publishDraftToProd, discardDraft } from "../helpers/editorHelper";

import useCompleoCareerPageContext from "../useCompleoCareerPageContext";

import dynamic from "next/dynamic";

const GlobalNotifications = dynamic(
    () => {
        const promise = import("components/GlobalNotifications").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const ConfirmationDialog = dynamic(
    () => {
        const promise = import("components/ConfirmationDialog").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const SiteEditorDrawer = dynamic(
    () => {
        const promise = import("components/SiteEditor").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

const PreviewMode = dynamic(
    () => {
        const promise = import("components/SiteEditor/PreviewMode").then(
            (mod) => mod.default
        );
        return promise;
    },
    { loading: () => null, ssr: false }
);

import CareerSection from "./CareerSection";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: "3rem",
            backgroundColor: theme.palette?.background?.paper ?? "white",
            paddingBottom: "20px",
        },
        fixedButton: {
            color: "white",
            backgroundColor: "#47525d",
            position: "fixed",
            top: "50%",
            left: 0,
            marginTop: "-2.5em",
            transform: "rotate(270deg)",
            transformOrigin: "15% 50%",
            zIndex: 99,
            "&:hover": {
                backgroundColor: "white",
                color: "#47525d",
            },
        },
        editorButton: {
            color: "white",
            backgroundColor: "#47525d",
            borderRadius: "4px",
            paddingTop: 15,
            paddingBottom: 15,
            marginRight: -2,
            right: 0,
            zIndex: 99,
            "&:hover": {
                backgroundColor: "white",
                color: "#47525d",
            },
        },
        sectionTitle: {
            paddingLeft: "8px",
            paddingTop: "20px",
            paddingBottom: "8px",
            color: theme.palette.text.primary,
            fontFamily: theme.typography.h3.fontFamily,
            fontSize: theme.typography.h3.fontSize,
            fontWeight: theme.typography.h3.fontWeight,
            lineHeight: theme.typography.h3.lineHeight,
        },
        confirmButton: {
            color: "#fff",
            backgroundColor: "#3d3d3d",
            "&:hover": {
                backgroundColor: "#bcbcbc",
                color: "#000",
            },
        },
    })
);
interface ICareerPageProps {
    uploadFilesFunction: (
        files: any[],
        isPublicBucket?: boolean
    ) => Promise<Compleo.IObject[]>; //any
    router: NextRouter;
}

export const CareerPage = (props: ICareerPageProps) => {
    const { uploadFilesFunction, router } = props;

    const classes = useStyles();
    const theme = useTheme();

    const [data] = useCompleoCareerPageContext();

    const { metadata, companyId, uniqueLink, hasDraft, showEditCareersButton } =
        data;

    const [openDrawer, setDrawerOpen] = React.useState(false);
    const [openDialog, setDialogOpen] = React.useState(false);
    const [openDiscardChangesDialog, setDiscardChangesDialogOpen] =
        React.useState(false);
    const [openPublishChangesDialog, setPublishChangesDialogOpen] =
        React.useState(false);
    const [previewBarOnBottom, setPreviewBarOnBottom] = React.useState(false);

    const { isPreview } = router;

    useEffect(() => {
        if (document && router.isPreview && hasDraft) {
            const footerElement = document.querySelector("footer");

            if (footerElement) {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                setPreviewBarOnBottom(true);
                            } else {
                                setPreviewBarOnBottom(false);
                            }
                        });
                    },
                    { threshold: 0.5 }
                );

                observer.observe(footerElement);
            }
        }
    }, [router, hasDraft]);

    const handleDrawerOpen = useCallback(async () => {
        if (hasDraft && !isPreview) {
            setDialogOpen(true);
        } else {
            setDrawerOpen(true);
        }
    }, [hasDraft, isPreview]);

    const handleDrawerClose = useCallback(() => {
        setDrawerOpen(false);
    }, []);

    const handleDiscardChangesDialogClose = useCallback(() => {
        setDiscardChangesDialogOpen(false);
    }, []);

    const handlePublishChangesDialogClose = useCallback(() => {
        setPublishChangesDialogOpen(false);
    }, []);

    const confirmDiscardChanges = useCallback(() => {
        setDiscardChangesDialogOpen(true);
    }, []);

    const confirmPublishChanges = useCallback(() => {
        setPublishChangesDialogOpen(true);
    }, []);

    const showPreviewPage = useCallback((clearPreview: boolean) => {
        router.push(
            `/${uniqueLink}/design/preview?redirectTo=${router.asPath}&clearPreview=${clearPreview}`
        );
    }, []);

    const handleConfirmPublish = useCallback(async () => {
        await publishDraftToProd(companyId, uniqueLink);
        showPreviewPage(false);
    }, [companyId, uniqueLink, showPreviewPage]);

    const handleConfirmDiscard = useCallback(async () => {
        await discardDraft(companyId, uniqueLink);

        showPreviewPage(false);
    }, [companyId, uniqueLink, showPreviewPage]);

    let lastOrderItem = -1;
    const componentsPage = metadata
        ?.sort((a: any, b: any) => a.order - b.order)
        .map((item: any) => {
            const lastOrderParam = lastOrderItem;
            lastOrderItem = item.order;
            const component = (
                <CareerSection
                    key={item.componentName}
                    componentMetadata={item}
                    classes={classes}
                    uploadFilesFunction={uploadFilesFunction}
                    keyData={router?.query}
                    redirectTo={
                        router?.query?.to
                            ? isArray(router?.query?.to) &&
                              router.query.to.length > 0
                                ? router?.query?.to[0]
                                : String(router?.query?.to)
                            : undefined
                    }
                />
            );

            return component;
        });

    const showPreview =
        router.isPreview && router.pathname.includes("/design") && hasDraft;

    return (
        <React.Fragment>
            {showPreview && (
                <div>
                    <div>{componentsPage}</div>
                    <div>
                        <PreviewMode
                            handleDiscardChanges={confirmDiscardChanges}
                            handlePublishChanges={confirmPublishChanges}
                            isPositionFixed={!previewBarOnBottom}
                        ></PreviewMode>
                    </div>
                </div>
            )}
            {!showPreview && componentsPage}
            {showEditCareersButton && (
                <>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={handleDrawerOpen}
                        className={classes.fixedButton}
                    >
                        Editar Site
                    </Button>
                    <SiteEditorDrawer
                        open={openDrawer}
                        handleDrawerClose={handleDrawerClose}
                        uploadFilesFunction={uploadFilesFunction}
                        showPreviewPageFunction={showPreviewPage}
                        theme={theme}
                        handleDiscardChanges={confirmDiscardChanges}
                    ></SiteEditorDrawer>

                    <ConfirmationDialog
                        open={openDiscardChangesDialog}
                        handleClose={handleDiscardChangesDialogClose}
                        handleConfirmation={handleConfirmDiscard}
                        title="Confirmar exclusão"
                        content="Deseja realmente descartar o rascunho existente
                        da última edição do site de Carreiras?"
                        noButtonTitle="NÃO"
                        yesButtonTitle="SIM"
                        isDesignMode={true}
                    />
                    <ConfirmationDialog
                        open={openPublishChangesDialog}
                        handleClose={handlePublishChangesDialogClose}
                        handleConfirmation={handleConfirmPublish}
                        title="Confirmar publicação em Produção"
                        content="Deseja realmente publicar em Produção o rascunho
                        existente da edição do site de Carreiras?"
                        noButtonTitle="NÃO"
                        yesButtonTitle="SIM"
                        isDesignMode={true}
                    />
                </>
            )}
            <GlobalNotifications />
        </React.Fragment>
    );
};

export default CareerPage;
