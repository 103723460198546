import axios, { AxiosResponse } from "axios";
import { getSession } from "next-auth/react";
import axiosRetry from "axios-retry";
import apiDirectCallAWS4 from "./apiAWS4";
import apiDirectCallAWS4Fetch from "./apiAWS4Fetch";

axiosRetry(axios, {
    retryDelay: (retryCount) => {
        return retryCount * 1000;
    },
});
interface IObject {
    [key: string]: any;
}

type AuthType = "none" | "bearer" | "credentials";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (typeof error.response === "undefined") {
            console.log("axios error response undefined", error);
        }
        return Promise.reject(error);
    }
);

export const apiDirectCall = async (
    endpoint: string,
    verb: AxiosType = "get",
    params: IObject = {},
    options: IObject = {},
    fullUrl: boolean = false,
    authType: AuthType = "bearer",
    useAWS4Fetch: boolean = false
) => {
    const session = await getSession();
    const initialEndPoint = process.env.NEXT_PUBLIC_REACT_APP_API_ADDRESS;

    let optionsRequest: IObject = {};
    //console.log(session ? "logged" : "notlogged");

    if (authType === "credentials") {
        if (useAWS4Fetch) {
            const fetchResponse = await apiDirectCallAWS4Fetch(
                endpoint,
                verb,
                params,
                options,
                fullUrl
            );

            const axiosResponse: AxiosResponse<any, any> = {
                status: fetchResponse.status,
                statusText: fetchResponse.statusText,
                data: {},
                headers: {},
                config: {},
            };
            return axiosResponse;
        }
        const response = await apiDirectCallAWS4(
            endpoint,
            verb,
            params,
            options,
            fullUrl
        );

        return response;
    } else if (authType === "bearer" && session?.idToken) {
        const authHeader = await getBearerAuthHeader(String(session.idToken));
        optionsRequest.headers = { ...(options.headers || {}), ...authHeader };
    }

    optionsRequest = { ...options, ...optionsRequest };

    let endPointLocal = initialEndPoint + endpoint;
    if (fullUrl) {
        endPointLocal = endpoint;
    }
    if (verb === "get") {
        const response = await axios.get(endPointLocal, optionsRequest);
        return response;
    } else if (verb === "post") {
        const response = await axios.post(
            endPointLocal,
            params,
            optionsRequest
        );
        axios.interceptors;

        return response;
    } else {
        const response = await axios.put(endPointLocal, params, optionsRequest);
        return response;
    }
};

const getBearerAuthHeader = async (idToken: string) => {
    return {
        Authorization: `Bearer ${idToken}`,
    };
};

export type AxiosType = "get" | "post" | "put";
