import React, { ComponentType, Component, ReactNode, useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core";

interface IBaseProps {
    children: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
        height: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
    },
}));

const Default = (props: IBaseProps) => {
    const { children } = props;
    const classes = useStyles();

    return <div className={classes.content}>{children}</div>;
};

export default Default;
