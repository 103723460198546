import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YoutubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Link from "@material-ui/core/Link";
import useTranslation from "next-translate/useTranslation";
import toLower from "lodash/toLower";
import useCompleoCareerPageContext from "../../useCompleoCareerPageContext";
interface IFooterProps {
    name: string;
    metadata: any;
    careerPageClasses: any;
}

const Footer = ({ name, metadata }: IFooterProps) => {
    const [data] = useCompleoCareerPageContext();
    const { moduleName } = data;
    const { t } = useTranslation(moduleName);

    const sectionStyle = metadata.customStyle?.filter(
        (fc: any) => fc.name === "section"
    )[0];

    const titleStyle = metadata.customStyle?.filter(
        (fc: any) => fc.name === "title"
    )[0];

    const linkStyle = metadata.customStyle?.filter(
        (fc: any) => fc.name === "linkText"
    )[0];

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
                padding: ".5rem",
                backgroundColor:
                    sectionStyle?.backgroundColor || theme.palette.primary.main,
            },
            gridRoot: {
                paddingTop: "15px",
            },
            footerGroupTitle: {
                fontFamily: theme.typography.h5.fontFamily,
                fontSize: titleStyle?.fontSize || theme.typography.h5.fontSize,
                fontWeight: theme.typography.h5.fontWeight,
                lineHeight: theme.typography.h5.lineHeight,
                color:
                    titleStyle?.fontColor ||
                    theme.palette.getContrastText(theme.palette.primary.main),
            },
            listRoot: {
                paddingLeft: "10px",
                listStyleType: "none",
            },
            links: {
                fontSize:
                    linkStyle?.fontSize || theme.typography.body1.fontSize,
                color:
                    linkStyle?.fontColor ||
                    theme.palette.getContrastText(theme.palette.primary.main),
                textDecoration: "none",
                "&:hover": {
                    color:
                        linkStyle?.fontColorHover ||
                        theme.palette.secondary.main,
                    textDecoration: "underline",
                },
            },
        })
    );

    const classes = useStyles();

    return (
        <footer
            style={{
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingTop: "15px",
                marginLeft: "0px",
                marginRight: "0px",
                marginTop: "auto",
            }}
        >
            <div className={classes.root}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-around"
                    className={classes.gridRoot}
                >
                    {metadata.groups
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((group: any) => {
                            const groupItems = metadata.items.filter(
                                (i: any) => i.groupId == group.id
                            );
                            return (
                                <Grid item key={group.id}>
                                    <Typography
                                        variant="h5"
                                        className={classes.footerGroupTitle}
                                    >
                                        {t(`${name}_group_${group.id}_title`)}
                                    </Typography>
                                    <ul className={classes.listRoot}>
                                        {groupItems
                                            .sort(
                                                (a: any, b: any) =>
                                                    a.order - b.order
                                            )
                                            .map((item: any) => {
                                                let resultElement: any;

                                                switch (item.linkType) {
                                                    case "icon":
                                                        resultElement = (
                                                            <li
                                                                key={
                                                                    item.elementName
                                                                }
                                                                style={
                                                                    group.itemsDirection ===
                                                                    "horizontal"
                                                                        ? {
                                                                              display:
                                                                                  "inline",
                                                                              float: "left",
                                                                              paddingRight:
                                                                                  "15px",
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <a
                                                                    href={
                                                                        item.href
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <IconButton
                                                                        style={
                                                                            group.itemsDirection ===
                                                                            "horizontal"
                                                                                ? {
                                                                                      padding:
                                                                                          "0px",
                                                                                  }
                                                                                : {
                                                                                      paddingLeft:
                                                                                          "0px",
                                                                                  }
                                                                        }
                                                                        className={
                                                                            classes.links
                                                                        }
                                                                    >
                                                                        {getIcon(
                                                                            item.iconName
                                                                        )}
                                                                    </IconButton>
                                                                </a>
                                                            </li>
                                                        );
                                                        break;
                                                    default:
                                                        resultElement = (
                                                            <li
                                                                key={
                                                                    item.elementName
                                                                }
                                                                style={
                                                                    group.itemsDirection ===
                                                                    "horizontal"
                                                                        ? {
                                                                              display:
                                                                                  "inline",
                                                                              float: "left",
                                                                              paddingRight:
                                                                                  "15px",
                                                                          }
                                                                        : undefined
                                                                }
                                                            >
                                                                <Typography variant="body2">
                                                                    {item.href.includes(
                                                                        "http"
                                                                    ) ? (
                                                                        <a
                                                                            href={
                                                                                item.href
                                                                            }
                                                                            target="_blank"
                                                                            className={
                                                                                classes.links
                                                                            }
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {t(
                                                                                `${name}_${item.elementName}_text`
                                                                            )}
                                                                        </a>
                                                                    ) : (
                                                                        <Link
                                                                            href={
                                                                                item.href
                                                                            }
                                                                            variant="body2"
                                                                            className={
                                                                                classes.links
                                                                            }
                                                                        >
                                                                            {t(
                                                                                `${name}_${item.elementName}_text`
                                                                            )}
                                                                        </Link>
                                                                    )}
                                                                </Typography>
                                                            </li>
                                                        );
                                                        break;
                                                }

                                                return resultElement;
                                            })}
                                    </ul>
                                </Grid>
                            );
                        })}
                </Grid>
            </div>
        </footer>
    );
};

const getIcon = (iconName: string) => {
    let icon: any;

    switch (toLower(iconName)) {
        case "facebook":
            icon = <FacebookIcon />;
            break;
        case "twitter":
            icon = <TwitterIcon />;
            break;
        case "linkedin":
            icon = <LinkedInIcon />;
            break;
        case "youtube":
            icon = <YoutubeIcon />;
            break;
        case "instagram":
            icon = <InstagramIcon />;
            break;
        case "whatsapp":
            icon = <WhatsAppIcon />;
            break;
        default:
            break;
    }

    return icon;
};

export default Footer;
